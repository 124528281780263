<template>
  <div id="bindPackageDialog">
    <el-dialog
      title="绑定套餐"
      :visible.sync="show"
      :close-on-press-escape="false"
      @closed="reset"
    >
      <el-form
        :model="addForm"
        :rules="addFormRules"
        ref="addForm"
        label-width="80px"
      >
        <el-form-item label="停车场" prop="parkingLotId">
          <el-select
            v-model="addForm.parkingLotId"
            placeholder="请选择停车场"
            style="width: 100%"
          >
            <el-option
              v-for="item of parkingList"
              :key="item.parkingLotId"
              :label="item.parkingLotName"
              :value="item.parkingLotId"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="租赁类型" prop="leaseType">
          <el-select
            v-model="addForm.leaseType"
            placeholder="请选择租赁类型"
            style="width: 100%"
          >
            <el-option
              v-for="item of select_leaseType"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="开始时间"
          prop="startTime"
          v-if="
            addForm.leaseType === '按年' ||
              addForm.leaseType === '按月' ||
              addForm.leaseType === '按日'
          "
        >
          <el-date-picker
            type="datetime"
            placeholder="选择日期"
            v-model="addForm.startTime"
            style="width: 100%"
          ></el-date-picker>
        </el-form-item>
        <el-form-item
          label="时长"
          prop="timeUnit"
          v-if="
            addForm.leaseType === '按年' ||
              addForm.leaseType === '按月' ||
              addForm.leaseType === '按日'
          "
        >
          <el-input
            v-model="addForm.timeUnit"
            placeholder="请输入时长"
          ></el-input>
        </el-form-item>
        <el-form-item label="备注">
          <el-input
            type="textarea"
            v-model="addForm.remarks"
            placeholder="请输入备注"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button size="medium" @click="show = false">取 消</el-button>
        <el-button
          type="primary"
          size="medium"
          @click="confirmBtn"
          :loading="loading"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  props: ["getList"],
  data() {
    return {
      show: false,
      loading: false,
      parkingList: [],
      addForm: {
        parkingLotId: "",
        plateNum: "",
        leaseType: "",
        startTime: "",
        timeUnit: "",
        remarks: "",
      },
      addFormRules: {
        leaseType: [
          { required: true, message: "请选择租赁类型", trigger: "change" },
        ],
        parkingLotId: [
          { required: true, message: "请选择停车场", trigger: "change" },
        ],
        startTime: [
          {
            required: true,
            message: "请选择开始时间",
            trigger: "change",
          },
        ],
        timeUnit: [{ required: true, message: "请输入时长", trigger: "blur" }],
      },
    };
  },
  computed: {
    parkingLotList() {
      return this.$store.state.select.parkingLotList;
    },
  },
  methods: {
    reset() {
      this.$set(this, "addForm", {
        plateNum: "",
        leaseType: "",
        startTime: "",
        timeUnit: "",
        remarks: "",
      });
    },
    async confirmBtn() {
      let falg = true;
      this.$refs["addForm"].validate((valid) => {
        if (!valid) {
          falg = false;
        }
      });
      if (!falg) {
        return;
      }
      try {
        this.loading = true;
        let res = await this.$http.post("/price/lease/add", {
          leaseType: this.addForm.leaseType,
          plateNum: this.addForm.plateNum,
          remarks: this.addForm.remarks,
          startTime: this.addForm.startTime ? this.addForm.startTime : null,
          timeUnit: this.addForm.timeUnit ? this.addForm.timeUnit : null,
          parkingLotId: this.addForm.parkingLotId,
        });
        if (res.code === 0) {
          this.getList();
          this.$message.success("添加成功");
          this.show = false;
        } else {
          this.$message.error(res.msg);
        }
      } catch (err) {
        this.$message.error(err);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
<style lang="scss">
#bindPackageDialog {
  .el-dialog__wrapper {
    .el-dialog {
      width: 400px;
      .el-dialog__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 15px;
        border: 1px solid rgba(20, 34, 57, 0.13);
        .el-dialog__title {
          font-size: 14px;
          color: #142239;
          position: relative;
          z-index: 2;
          font-weight: bold;
          &::after {
            content: "";
            display: inline-block;
            width: 100%;
            height: 3px;
            background-color: $main-color;
            position: absolute;
            left: 0;
            bottom: 4px;
            z-index: -1;
          }
        }
        .el-dialog__headerbtn {
          position: initial;
          i {
            color: black;
          }
        }
      }
      .el-dialog__body {
        padding: 15px;
      }
      .el-dialog__footer {
        text-align: center;
        .dialog-footer {
          .el-button--default {
            border-color: $main-color;
            color: $main-color;
          }
        }
      }
    }
  }
}
</style>
